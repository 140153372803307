.App {
  position: relative;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background: #efefef;
}

body {
  top: 0 !important;
}

a {
  /* text-decoration: none; */
}